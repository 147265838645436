import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { mediaUtils } from '@infominds/react-native-media'
import { differenceInCalendarDays, isAfter } from 'date-fns'
import dayjs from 'dayjs'
import { i18n } from 'i18next'
import cloneDeep from 'lodash/cloneDeep'
import mapKeys from 'lodash/mapKeys'
import { DimensionValue } from 'react-native'

import {
  ActivitySparePart,
  ActivityTypeGet,
  Contract,
  SerialNumberCounter,
  StateResponse,
  Ticket,
  TicketActivity,
  TicketPriorityResponse,
  UserSettings,
} from '../apis/types/apiResponseTypes'
import { defaultIconType, TicketIcons } from '../constants/IconTypes'
import { FilterType, GroupType, OrderType } from '../contexts/FilterContext'
import { QualityCheckGroupType, QualityCheckOrderType } from '../contexts/QualityCheckFilterContext'
import { QualityFilterType, QualityGroupType, QualityOrderType } from '../contexts/QualityFilterProvider'
import { SparePartsFilterType, SparePartsOrderType } from '../contexts/SparePartsFilterContext'
import {
  ActivityType,
  Filter,
  FilterElement,
  PartDto,
  PartStatus,
  ReadingType,
  readingTypes,
  SelectorReadingType,
  SerialNumberState,
  SparePartDirection,
  ThemeColorExpanded,
  UploadStatus,
} from '../types'
import TimeUtils from './TimeUtils'

const ticketUtils = {
  getTicketIconByActivity(activityType: ActivityType | undefined): IconProp {
    if (activityType === undefined) return defaultIconType.icon

    return TicketIcons.find(t => t.type === activityType)?.icon ?? defaultIconType.icon
  },
  getStartEndTime(ticket: Ticket) {
    return {
      startTime: ticket.planStartTime ? TimeUtils.revertSecondsToDate(ticket.planStartTime) : null,
      endTime: ticket.planEndTime && ticket.planEndTime > 0 ? TimeUtils.revertSecondsToDate(ticket.planEndTime) : null,
    }
  },
  getSerialNumberString<T extends { serialnumber?: string; serialnumberManufacturerNumber?: string }>(obj: T) {
    if (!obj?.serialnumber) return ''
    let serial = obj.serialnumber
    if (obj.serialnumberManufacturerNumber) serial += ` (${obj.serialnumberManufacturerNumber})`
    return serial
  },
  calculateRatio(
    fromDate?: string,
    untilDate?: string,
    theme?: ThemeColorExpanded
  ): {
    width: DimensionValue
    color: string | undefined
    expired: boolean
  } {
    const from = fromDate ? new Date(fromDate) : new Date()
    const until = untilDate
      ? new Date(TimeUtils.resetTimeOfISOString(new Date(untilDate).toISOString(), 24 * 60 * 60 - 1))
      : new Date(TimeUtils.resetTimeOfISOString(new Date().toISOString(), 24 * 60 * 60 - 1))

    if (isAfter(new Date(), until)) {
      return { width: '100%', color: theme?.article.status.red, expired: true }
    } else if (isAfter(from, new Date())) {
      return { width: '0%', color: theme?.article.status.green, expired: false }
    } else {
      const totalContractDuration = differenceInCalendarDays(until, from)
      const remainingDays = differenceInCalendarDays(until, new Date())

      const ratio = 1 - remainingDays / totalContractDuration
      const width: DimensionValue = `${ratio * 100}%`

      if (ratio < 0.8) {
        return {
          width,
          color: theme?.article.status.green,
          expired: false,
        }
      } else if (ratio >= 0.8 && ratio < 1) {
        return {
          width,
          color: theme?.article.status.yellow,
          expired: false,
        }
      } else {
        return {
          width,
          color: theme?.article.status.red,
          expired: true,
        }
      }
    }
  },
  getWarrantyStatus(warrantyStart: string, warrantyEnd: string, theme?: ThemeColorExpanded) {
    return ticketUtils.calculateRatio(warrantyStart, warrantyEnd, theme)
  },
  getContractStatus(contract: Contract, theme?: ThemeColorExpanded) {
    return ticketUtils.calculateRatio(contract.validFrom, contract.validUntil, theme)
  },
  getActiveContract(contracts: Contract[] | undefined) {
    let toRet: Contract | undefined

    if (contracts) {
      try {
        contracts.forEach(contract => {
          const status = ticketUtils.getContractStatus(contract)

          if (status.width !== null) {
            let completion = 0

            if (typeof status.width === 'number') {
              completion = status.width
            } else if (status.width === 'auto' || typeof status.width === 'object') {
              /* empty */
            } else {
              const newValue = status.width.replace('%', '')
              completion = parseInt(newValue, 10)
            }

            if (completion > 0 && completion < 100) {
              toRet = contract
            }
          }
        })
      } catch (err) {
        console.error(err)
      }
    }

    return toRet
  },
  getSNState(state: SerialNumberState, theme: ThemeColorExpanded) {
    switch (state) {
      case 'Red':
        return theme.article.status.red
      case 'Green':
        return theme.article.status.green

      case 'Yellow':
        return theme.article.status.yellow

      case 'None':
        return theme.article.status.grey
    }
  },
  getInfoboxIcon(extension: string, theme: ThemeColorExpanded): { icon: IconProp; color: string; size?: number } {
    if (mediaUtils.isPdf(extension)) {
      return { icon: ['fal', 'file-pdf'], color: theme.general.pdf }
    }

    if (mediaUtils.isImage(extension)) {
      return { icon: ['fal', 'image'], color: theme.general.image }
    }

    const ext = extension.toLowerCase()

    switch (ext) {
      case 'doc':
      case 'docx': {
        return { icon: ['fal', 'file-doc'], color: theme.general.word }
      }
      case 'xls':
      case 'csv':
      case 'xlsx': {
        return { icon: ['fal', 'file-excel'], color: theme.general.excel, size: 16 }
      }
      default: {
        return { icon: ['fal', 'file'], color: theme.general.text }
      }
    }
  },
  calculateInitials(inputString: string) {
    if (!inputString) return ''

    const simplifiedString = inputString
      .replace(/[^a-zA-Z0-9\s]/g, '')
      .split(' ')
      .filter(el => el !== '')
    let initial = ''

    if (simplifiedString.length === 0) {
      initial = Math.random().toString(36).slice(2).substring(2, 4).toUpperCase()
    } else if (simplifiedString.length === 1) {
      initial = simplifiedString[0][0].toUpperCase() + Math.random().toString(36).slice(2).substring(2, 3).toUpperCase()
    } else {
      initial = `${simplifiedString[0][0].toUpperCase()}${simplifiedString[1][0].toUpperCase()}`
    }

    return initial
  },
  activeFilters<T extends { name: string; elements: FilterElement[] }>(filters: T[]) {
    const mappedFilter: T[] = []

    filters.forEach(filter => {
      const mappedFilterElement: FilterElement[] = []

      filter.elements.forEach(el => el.active && mappedFilterElement.push(el))

      if (mappedFilterElement.length !== 0) {
        mappedFilter.push({ ...filter, elements: mappedFilterElement })
      }
    })

    return mappedFilter
  },
  replaceUndefinedSection: <T>(obj: { [key: string]: T[] }, replaceWith: string) => {
    const specialChar = '\u0441'
    const mapped = mapKeys(obj, (_, key) => {
      if (key === 'undefined') {
        return specialChar
      }

      return key
    })

    const keys = Object.keys(mapped).sort((a, b) => a.localeCompare(b))

    const toRet: { [key: string]: T[] } = {}
    keys.forEach(key => {
      Object.assign(toRet, { ...toRet, [key]: mapped[key] })
    })

    return mapKeys(toRet, (_, key) => {
      if (key === specialChar) {
        return replaceWith
      }

      return key
    })
  },
  sortDateString: <T>(obj: T[], key: keyof T, type: 'asc' | 'desc', sameDateSorter?: (a: T, b: T) => number) => {
    const emptyValues = obj.filter(el => el[key] === '')
    const notEmptyValues = obj.filter(el => el[key] !== '')

    notEmptyValues.sort((a, b) => {
      const aKey = a[key] as string
      const bKey = b[key] as string

      console.log('bKey.localeCompare(aKey)', key, bKey, bKey.localeCompare(aKey))
      if (type === 'asc') {
        return aKey.localeCompare(bKey) || (sameDateSorter ? sameDateSorter(a, b) : 0)
      } else {
        return bKey.localeCompare(aKey) || (sameDateSorter ? sameDateSorter(a, b) : 0)
      }
    })

    return [...notEmptyValues, ...emptyValues]
  },
  mergeFilters(filter: Filter<FilterType> | Filter<QualityFilterType>, newFilterElements: FilterElement[]) {
    const toUpdate: FilterElement[] = []

    filter.elements.forEach(el => {
      const elFound = newFilterElements.find(act => act.id === el.id)

      if (elFound) {
        toUpdate.push({ ...el, description: elFound.description })
      }
    })

    newFilterElements.forEach(el => {
      const found = toUpdate.find(act => act.id === el.id)

      if (found === undefined) {
        toUpdate.push(el)
      }
    })

    toUpdate.sort((a, b) => a.id.localeCompare(b.id))

    return toUpdate
  },
  removeDuplicateFilters(filterElements: FilterElement[]) {
    return filterElements.reduce((acc: FilterElement[], el) => (acc.find(acc_el => acc_el.id === el.id) !== undefined ? acc : [...acc, el]), [])
  },
  getNameByType(
    type:
      | GroupType
      | OrderType
      | FilterType
      | SparePartsOrderType
      | SparePartsFilterType
      | QualityCheckGroupType
      | QualityCheckOrderType
      | QualityFilterType
      | QualityGroupType
      | QualityOrderType,
    translator: i18n
  ) {
    switch (type) {
      case FilterType.Teams:
        return translator.t('FILTER_TEAM')
      case FilterType.Activity:
        return translator.t('FILTER_ACTIVITY')
      case FilterType.Assistance:
        return translator.t('ASSISTANCE_TYPE')
      case GroupType.Zone:
      case FilterType.Zone:
        return translator.t('ZONE')
      case FilterType.PlanDate:
        return translator.t('PLAN_DATE_FROM_TO')
      case QualityGroupType.Customer:
      case GroupType.Customer:
        return translator.t('CUSTOMER')
      case GroupType.Assistance:
        return translator.t('ASSISTANCE_TYPE')
      case OrderType.DateDescending:
      case OrderType.DateAscending:
        return translator.t('DATE')
      case OrderType.PlanningDescending:
      case OrderType.PlanningAscending:
        return translator.t('START_PLANNING_DATE')
      case OrderType.TownDescending:
      case OrderType.TownAscending:
        return translator.t('TOWN')
      case OrderType.Priority:
        return translator.t('PRIORITY')
      case SparePartsOrderType.ArticleName:
        return translator.t('ARTICLE_NAME')
      case SparePartsOrderType.ArticleCode:
        return translator.t('ARTICLE_CODE')
      case QualityOrderType.SerialNumberDescending:
      case SparePartsOrderType.SerialNumberDescending:
      case QualityOrderType.SerialNumberAscending:
      case SparePartsOrderType.SerialNumberAscending:
        return translator.t('SERIAL_NUMBER')
      case SparePartsFilterType.Depots:
        return translator.t('DEPOT')
      case SparePartsFilterType.Compatible:
        return translator.t('COMPATIBILITY')
      case SparePartsFilterType.Stock:
        return translator.t('STOCK')
      case QualityFilterType.SerialNumber:
      case QualityCheckGroupType.SerialNumber:
        return translator.t('SERIAL_NUMBER')
      case QualityCheckGroupType.Attribute:
        return translator.t('ATTRIBUTE')
      case QualityCheckOrderType.AlphabeticalDescending:
      case QualityCheckOrderType.AlphabeticalAscending:
        return translator.t('ALPHABETICAL')
      case QualityGroupType.Ticket:
        return translator.t('TICKET')
      case QualityOrderType.SortKeyDescending:
      case QualityOrderType.SortKeyAscending:
        return translator.t('SORT_KEY')
    }
  },
  createCounterReading(translator: i18n) {
    const toRet: SelectorReadingType[] = []

    readingTypes.forEach(el => {
      switch (el) {
        case 'None':
          toRet.push({ id: 'None', value: translator.t('NONE') })
          break
        case 'Manual':
          toRet.push({ id: 'Manual', value: translator.t('RX_MANUAL') })
          break
        case 'StartCounter':
          toRet.push({ id: 'StartCounter', value: translator.t('RX_START_COUNTER') })
          break
        case 'Activity':
          toRet.push({ id: 'Activity', value: translator.t('RX_ACTIVITY') })
          break
        case 'TheoreticalCounterStatus':
          toRet.push({ id: 'TheoreticalCounterStatus', value: translator.t('RX_THEORETICAL_COUNTER_STATUS') })
          break
        case 'Telematic':
          toRet.push({ id: 'Telematic', value: translator.t('RX_TELEMATIC') })
          break
        case 'Monitoring':
          toRet.push({ id: 'Monitoring', value: translator.t('RX_MONITORING') })
          break
        case 'Calculated':
          toRet.push({ id: 'Calculated', value: translator.t('RX_CALCULATED') })
          break
      }
    })

    return toRet.sort((a, b) => a.value.localeCompare(b.value))
  },
  getCounterReadingTranslation(type: ReadingType, translator: i18n) {
    switch (type) {
      case 'None':
        return translator.t('NONE')
      case 'Manual':
        return translator.t('RX_MANUAL')
      case 'StartCounter':
        return translator.t('RX_START_COUNTER')
      case 'Activity':
        return translator.t('RX_ACTIVITY')
      case 'TheoreticalCounterStatus':
        return translator.t('RX_THEORETICAL_COUNTER_STATUS')
      case 'Telematic':
        return translator.t('RX_TELEMATIC')
      case 'Monitoring':
        return translator.t('RX_MONITORING')
      case 'Calculated':
        return translator.t('RX_CALCULATED')
    }
  },
  getArticleSn<T extends { serialnumber?: string; serialnumberNumberManufactor?: string; articleSearchtext: string }>(item: T) {
    return (
      (item.serialnumber ? item.serialnumber + (item.serialnumberNumberManufactor ? ` (${item.serialnumberNumberManufactor})` : '') : '') +
      (item.articleSearchtext !== '' ? '\n' + item.articleSearchtext : '')
    )
  },
  initShoppingCart(parts: ActivitySparePart[], direction: SparePartDirection): PartDto[] {
    return parts
      .filter(el => el.direction === direction)
      .map(part => {
        return {
          ...part,
          pickedPrice: part.price,
          initQuantity: part.quantity,
          initSerialnumberIdParent: part.serialnumberIdParent,
          status: 'init',
          prevStatus: 'init',
        }
      })
  },
  isShoppingCartEdited: <T extends { status: PartStatus }>(parts: T[]) => {
    return !parts.every(part => part.status === 'init')
  },
  resetSpareParts: (parts: PartDto[] | undefined) => {
    if (!parts) return undefined

    const clone = cloneDeep(parts).filter(el => el.prevStatus === 'init')

    clone.forEach(el => {
      el.status = 'init'
      el.quantity = el.initQuantity
    })

    return clone
  },
  updateSparePartFilters: (loadedFilter: Filter<SparePartsFilterType>, filtersToRet: Filter<SparePartsFilterType>[]) => {
    const activeFound = loadedFilter.elements.find(el => el.active)

    if (activeFound) {
      filtersToRet.forEach(
        filterToRet =>
          filterToRet.id === loadedFilter.id &&
          filterToRet.elements.forEach(el => {
            if (el.id === activeFound.id) {
              el.active = true
            } else {
              el.active = false
            }
          })
      )
    } else {
      filtersToRet.forEach(
        filterToRet =>
          filterToRet.id === loadedFilter.id &&
          filterToRet.elements.forEach(el => {
            el.active = false
          })
      )
    }
  },
  checkSparePartsPending: (parts: PartDto[], setStatus: (newStatus: UploadStatus) => void) => {
    if (parts === undefined) return

    if (parts.length === 0 || parts.every(part => part.status === 'init')) {
      setStatus('done')
    } else {
      setStatus('waiting')
    }
  },
  getSparePartStatus: (part: PartDto) => {
    let status: PartStatus = 'init'

    if (
      part.quantity === part.initQuantity &&
      (part.newDescription === undefined ? true : part.newDescription === part.articleDescription) &&
      part.serialnumberIdParent === part.initSerialnumberIdParent
    ) {
      status = 'init'
    } else {
      if (part.quantity === 0) {
        status = 'deleted'
      } else if (part.quantity === undefined) {
        status = 'mandatoryMissing'
      } else {
        status = 'edited'
      }
    }

    if (part.prevStatus === 'new' && status === 'edited') {
      status = 'new'
    }

    return status
  },
  getPartIdentifier: <
    T extends {
      articleCode: string
      articleSearchtext: string
      depositId: string
      date: string
      pickedPrice?: number
      serialnumberIdParent?: string
      newDescription?: string
      serialnumber?: string
      invoicingTypeId?: string
    },
  >(
    item: T,
    skipInvoiceTypeIdCheck = false
  ) => {
    return (
      item.articleCode +
      '#' +
      item.articleSearchtext +
      '#' +
      item.depositId +
      '#' +
      (item.pickedPrice ?? 0).toString() +
      '#' +
      (item.serialnumberIdParent ?? '') +
      '#' +
      (item.serialnumber ?? '') +
      '#' +
      item.date +
      '#' +
      (item.newDescription ?? '') +
      (skipInvoiceTypeIdCheck ? '' : '#' + (item.invoicingTypeId ?? ''))
    )
  },
  getPartIdentifierShoppingCart: (item: PartDto) => {
    return (
      item.articleCode +
      '#' +
      item.articleSearchtext +
      '#' +
      item.depositId +
      '#' +
      (item.price ?? 0).toString() +
      '#' +
      (item.pickedPrice ?? 0).toString() +
      '#' +
      item.date +
      '#' +
      (item.serialnumberIdParent ?? '') +
      '#' +
      (item.serialnumber ?? '') +
      '#' +
      (item.description ?? '') +
      '#' +
      (item.invoicingTypeId ?? '')
    )
  },
  orderTimeEntries: <T extends { employee: string; from: number; date: string }>(elements: T[]) => {
    let sortedStart = elements.sort((a, b) => b.from - a.from)
    sortedStart = TimeUtils.sortDate(sortedStart, 'date', 'desc')

    return sortedStart
  },
  closingCountersCheck(
    counters: SerialNumberCounter[] | undefined,
    userSettings: Omit<UserSettings, 'id' | 'menuItems'> | undefined
  ): boolean | undefined {
    if (userSettings && userSettings.mandatoryInputOfTheValueDuringCounterReading) {
      let checkPassed = true

      counters
        ?.filter(el => !el.calculationFormula)
        .forEach(el => {
          if (el.movementDate === undefined) {
            checkPassed = false
          } else {
            const counterDate = dayjs(el.movementDate).startOf('day')
            const todayDate = dayjs().startOf('day')

            if (checkPassed && counterDate.isBefore(todayDate)) {
              checkPassed = false
            }
          }
        })

      return checkPassed
    }

    return undefined
  },
  composePriorityString: (item: Pick<TicketPriorityResponse, 'code' | 'description'>) =>
    item.code === '' ? item.description : item.code + ' - ' + item.description,
  composeStateString: (item: Pick<StateResponse, 'code' | 'description'>) => item.code + ' - ' + item.description,
  composeActivityString: (item: Pick<ActivityTypeGet, 'code' | 'description'>) => item.code + ' - ' + item.description,
  isActivityEditable: (activity: TicketActivity, userSettings: UserSettings) => {
    return activity.state === 'Editing' && activity.employeeId && userSettings.employeeId === activity.employeeId ? true : false
  },
}

export default ticketUtils
