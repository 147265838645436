import { IM } from '@infominds/react-native-components'
import React from 'react'
import { Platform, StyleSheet } from 'react-native'

import useIsOnline from '../../../dataProvider/hooks/useIsOnline'
import DataManagementIconGroup, { DataManagementIconGroupProps } from '../DataManagementIconGroup'
import HeaderPlanningChangeViewIcon, { PlanningHeaderIconType } from '../HeaderPlanningChangeViewIcon'
import { LIST_ICON, PLANNER_ICON, PlanningScreenViewType } from '../types'
import SyncIcon from './SyncIcon'

interface Props {
  enabledOffline?: boolean
}

const PlanningHeader = ({ enabledOffline, ...props }: Props & Omit<DataManagementIconGroupProps, 'extraIcon'>) => {
  const isOnline = useIsOnline()

  const headerIcons: PlanningHeaderIconType[] = [
    { type: PlanningScreenViewType.LIST, ...PLANNER_ICON },
    { type: PlanningScreenViewType.PLANNER, ...LIST_ICON },
  ]

  return (
    <IM.View style={styles.container}>
      <IM.View style={styles.leftContainer}>
        <DataManagementIconGroup disabled={!enabledOffline && !isOnline} {...props} alignment="left" />
      </IM.View>
      <IM.View style={styles.rightContainer}>
        {Platform.OS === 'web' && <HeaderPlanningChangeViewIcon types={headerIcons} />}
        <SyncIcon />
      </IM.View>
    </IM.View>
  )
}

const styles = StyleSheet.create({
  container: { flex: 1, flexDirection: 'row' },
  rightContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  leftContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
})

export default PlanningHeader
